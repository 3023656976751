<template>
	<transition name="fade">
		<div
			v-if="modelValue"
			:class="['modal', { 'modal--transparent': transparentBackground }]"
			@click="hide">
			<div
				class="modal__wrapper"
				@click.stop>
				<nuxt-icon
					v-if="closedCross"
					class="icon modal__close"
					name="dropdown-close"
					@click="hide()" />

				<slot />
			</div>
		</div>
	</transition>
</template>

<script setup lang="ts">
	const {
		modelValue,
		closedOutside = true,
		closedCross = true,
		transparentBackground = false,
	} = defineProps<{
		modelValue: boolean;
		closedOutside?: boolean;
		closedCross?: boolean;
		transparentBackground?: boolean;
	}>();

	const emits = defineEmits<{
		(event: "update:modelValue", value: boolean): void;
	}>();

	const hide = () => {
		if (!closedOutside) return;

		emits("update:modelValue", false);
	};

	watch(
		() => modelValue,
		(val) => {
			const body = document.querySelector("body");

			if (val) {
				body?.classList.add("block-scroll");
			} else {
				body?.classList.remove("block-scroll");
			}
		},
	);
</script>

<style scoped lang="scss">
	@import "modal";
</style>
